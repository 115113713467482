var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalProviderAdd",
      attrs: {
        id: "modalProviderAdd",
        lazy: "",
        "no-fade": "",
        size: "lg",
        title: _vm.modalTitle,
        "cancel-title": _vm.$t("modalProviderAdd.words.cancel"),
        "ok-title": _vm.okTitle,
      },
      on: {
        show: _vm.handleOpen,
        cancel: _vm.handleCancel,
        hidden: _vm.handleCancel,
        ok: _vm.createOrUpdateContract,
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "data-test": "company_name",
                    label: _vm.$t("modalProviderAdd.texts.providerName"),
                    "label-for": "input-provider-name",
                    state: _vm.form.state.company_name,
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-provider-name",
                      state: _vm.form.state.company_name,
                      trim: "",
                    },
                    model: {
                      value: _vm.form.inputs.company_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.inputs, "company_name", $$v)
                      },
                      expression: "form.inputs.company_name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("mask-input", {
            attrs: {
              "mask-label": this.$t("modalProviderAdd.words.companyVat"),
              "parent-class": "col-md-4 col-sm-12",
              mask: _vm.$t("modalProviderAdd.masks.companyVat"),
              "field-state": _vm.form.state.company_vat,
              uppercase: true,
              disabled: _vm.isUpdate,
            },
            model: {
              value: _vm.form.inputs.company_vat,
              callback: function ($$v) {
                _vm.$set(_vm.form.inputs, "company_vat", $$v)
              },
              expression: "form.inputs.company_vat",
            },
          }),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "data-test": "status",
                    label: _vm.$t("modalProviderAdd.words.status"),
                    "label-for": "input-status-name",
                    state: _vm.form.state.status,
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "input-status-name",
                      state: _vm.form.state.status,
                      options: _vm.statusList,
                      trim: "",
                    },
                    model: {
                      value: _vm.form.inputs.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.inputs, "status", $$v)
                      },
                      expression: "form.inputs.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "data-test": "contact_name",
                    label: _vm.$t("modalProviderAdd.texts.contactName"),
                    "label-for": "input-contact-name",
                    state: _vm.form.state.contact_name,
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-contact-name",
                      state: _vm.form.state.contact_name,
                      trim: "",
                    },
                    model: {
                      value: _vm.form.inputs.contact_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.inputs, "contact_name", $$v)
                      },
                      expression: "form.inputs.contact_name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("mask-input", {
            attrs: {
              "data-test": "contact_phone",
              "mask-label": _vm.$t("modalProviderAdd.texts.contactPhone"),
              "parent-class": "col-md-4 col-sm-12",
              mask: _vm.$t("modalProviderAdd.masks.phone"),
              "field-state": _vm.form.state.contact_phone,
            },
            model: {
              value: _vm.form.inputs.contact_phone,
              callback: function ($$v) {
                _vm.$set(_vm.form.inputs, "contact_phone", $$v)
              },
              expression: "form.inputs.contact_phone",
            },
          }),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "data-test": "contact_email",
                    label: _vm.$t("modalProviderAdd.texts.contactEmail"),
                    "label-for": "input-contact-email",
                    state: _vm.form.state.contact_email,
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-contact-email",
                      state: _vm.form.state.contact_email,
                      trim: "",
                    },
                    model: {
                      value: _vm.form.inputs.contact_email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.inputs, "contact_email", $$v)
                      },
                      expression: "form.inputs.contact_email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "data-test": "contract_number",
                    label: _vm.$t("modalProviderAdd.texts.contractNumber"),
                    "label-for": "input-contract-number",
                    state: _vm.form.state.contract_number,
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-contract-number",
                      state: _vm.form.state.contract_number,
                      trim: "",
                    },
                    model: {
                      value: _vm.form.inputs.contract_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.inputs, "contract_number", $$v)
                      },
                      expression: "form.inputs.contract_number",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "data-test": "contract_date",
                    label: _vm.$t("modalProviderAdd.texts.contractDate"),
                    "label-for": "input-contact-date",
                    state: _vm.form.state.contract_date,
                  },
                },
                [
                  _c("date-picker", {
                    attrs: {
                      id: "input-contract-date",
                      hide: _vm.isUpdate,
                      "field-state": _vm.form.state.contract_date,
                    },
                    model: {
                      value: _vm.form.inputs.contract_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.inputs, "contract_date", $$v)
                      },
                      expression: "form.inputs.contract_date",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-dd-divider", { staticClass: "mb-3" }),
          _c(
            "b-col",
            { attrs: { sm: "12" } },
            [
              _c("address-edit", {
                attrs: {
                  street_no_number: _vm.form.inputs.address_street_no_number,
                  postal_code: _vm.form.inputs.address_postal_code,
                  country: _vm.form.inputs.address_country,
                  city: _vm.form.inputs.address_city,
                  state: _vm.form.inputs.address_state,
                  street_name: _vm.form.inputs.address_street_name,
                  street_number: _vm.form.inputs.address_street_number,
                  street_details: _vm.form.inputs.address_street_details,
                  neighborhood: _vm.form.inputs.address_neighborhood,
                  validation: _vm.state.addressForm,
                },
                on: { "update-form-data": _vm.handleAddressUpdated },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }