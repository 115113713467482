<template>
  <b-modal
    id="modalProviderAdd"
    ref="modalProviderAdd"
    lazy
    no-fade
    size="lg"
    :title="modalTitle"
    :cancel-title="$t('modalProviderAdd.words.cancel')"
    :ok-title="okTitle"
    @show="handleOpen"
    @cancel="handleCancel"
    @hidden="handleCancel"
    @ok="createOrUpdateContract"
  >
    <b-row>
      <b-col sm="12" md="4">
        <b-form-group
          data-test="company_name"
          :label="$t('modalProviderAdd.texts.providerName')"
          label-for="input-provider-name"
          :state="form.state.company_name"
        >
          <b-form-input
            id="input-provider-name"
            v-model="form.inputs.company_name"
            :state="form.state.company_name"
            trim
          />
        </b-form-group>
      </b-col>

      <mask-input
        v-model="form.inputs.company_vat"
        :mask-label="this.$t('modalProviderAdd.words.companyVat')"
        parent-class="col-md-4 col-sm-12"
        :mask="$t('modalProviderAdd.masks.companyVat')"
        :field-state="form.state.company_vat"
        :uppercase="true"
        :disabled="isUpdate"
      />

      <b-col sm="12" md="4">
        <b-form-group
          data-test="status"
          :label="$t('modalProviderAdd.words.status')"
          label-for="input-status-name"
          :state="form.state.status"
        >
          <b-form-select
            id="input-status-name"
            v-model="form.inputs.status"
            :state="form.state.status"
            :options="statusList"
            trim
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="4">
        <b-form-group
          data-test="contact_name"
          :label="$t('modalProviderAdd.texts.contactName')"
          label-for="input-contact-name"
          :state="form.state.contact_name"
        >
          <b-form-input
            id="input-contact-name"
            v-model="form.inputs.contact_name"
            :state="form.state.contact_name"
            trim
          />
        </b-form-group>
      </b-col>

      <mask-input
        v-model="form.inputs.contact_phone"
        data-test="contact_phone"
        :mask-label="$t('modalProviderAdd.texts.contactPhone')"
        parent-class="col-md-4 col-sm-12"
        :mask="$t('modalProviderAdd.masks.phone')"
        :field-state="form.state.contact_phone"
      />

      <b-col sm="12" md="4">
        <b-form-group
          data-test="contact_email"
          :label="$t('modalProviderAdd.texts.contactEmail')"
          label-for="input-contact-email"
          :state="form.state.contact_email"
        >
          <b-form-input
            id="input-contact-email"
            v-model="form.inputs.contact_email"
            :state="form.state.contact_email"
            trim
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="4">
        <b-form-group
          data-test="contract_number"
          :label="$t('modalProviderAdd.texts.contractNumber')"
          label-for="input-contract-number"
          :state="form.state.contract_number"
        >
          <b-form-input
            id="input-contract-number"
            v-model="form.inputs.contract_number"
            :state="form.state.contract_number"
            trim
          />
        </b-form-group>
      </b-col>

      <b-col sm="12" md="4">
        <b-form-group
          data-test="contract_date"
          :label="$t('modalProviderAdd.texts.contractDate')"
          label-for="input-contact-date"
          :state="form.state.contract_date"
        >
          <date-picker
            id="input-contract-date"
            v-model="form.inputs.contract_date"
            :hide="isUpdate"
            :field-state="form.state.contract_date"
          />
        </b-form-group>
      </b-col>
      <b-dd-divider class="mb-3" />

      <b-col sm="12">
        <address-edit
          :street_no_number="form.inputs.address_street_no_number"
          :postal_code="form.inputs.address_postal_code"
          :country="form.inputs.address_country"
          :city="form.inputs.address_city"
          :state="form.inputs.address_state"
          :street_name="form.inputs.address_street_name"
          :street_number="form.inputs.address_street_number"
          :street_details="form.inputs.address_street_details"
          :neighborhood="form.inputs.address_neighborhood"
          :validation="state.addressForm"
          @update-form-data="handleAddressUpdated"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import AddressEdit from '@components/address-edit';
import DatePicker from '@components/date-picker';
import KoviForm from '@utils/helper-form';
import { STATUS } from '@enums/provider';
import {
  create as createContractService,
  update as updateContractService,
} from '@graphql/contract/services';
import { isMexico } from '@utils/helper-region';

export default {
  name: 'ModalProviderAdd',
  components: {
    AddressEdit,
    DatePicker,
  },
  props: {
    provider: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    statusList: [
      { value: 'ACTIVE', text: 'Ativo' },
      { value: 'INACTIVE', text: 'Inativo' },
    ],
    state: {
      addressForm: null,
    },
    form: KoviForm({
      address_street_no_number: '',
      address_country: '',
      address_postal_code: '',
      company_name: '',
      company_vat: '',
      status: STATUS.ACTIVE,
      contact_name: '',
      contact_phone: '',
      contact_email: '',
      contract_number: '',
      contract_date: '',
    }),
  }),
  computed: {
    okTitle() {
      return this.provider.id
        ? this.$t('modalProviderAdd.texts.updateContract')
        : this.$t('modalProviderAdd.texts.createNewContract');
    },
    modalTitle() {
      return this.provider.id
        ? this.$t('modalProviderAdd.texts.updateProvider')
        : this.$t('modalProviderAdd.texts.addNewProvider');
    },
    isUpdate() {
      return !!this.provider.id;
    },
    isMexico() {
      return isMexico();
    },
  },
  watch: {
    provider(newValue) {
      const hasNewProviderData = Object.keys(newValue).length;

      if (hasNewProviderData) {
        this.form.inputs = {
          ...this.form.inputs,
          ...newValue,
          contract_date: newValue.contract_date || '',
        };
      }
    },
  },
  mounted() {
    this.form.addKeysToIgnore(
      'address_street_no_number',
      'address_country',
      'address_postal_code',
      'address_street_details'
    );
    this.form.addRules({
      company_vat: value =>
        value ? (!isMexico() ? value.length === 14 : true) : false,
      address_street_number: value =>
        this.form.inputs.address_street_no_number || !!value,
    });
  },
  methods: {
    resetModal() {
      this.state.addressForm = false;
      this.form.resetInputsValidation({
        status: STATUS.ACTIVE,
      });
    },
    handleOpen() {
      this.resetModal();
    },
    handleCancel() {
      this.resetModal();
      this.$emit('close');
    },
    handleAddressUpdated(target, event) {
      this.form.inputs[`address_${target}`] = event;
    },
    createOrUpdateContract(eventModal) {
      eventModal.preventDefault();

      this.state.addressForm = true;
      if (!this.form.validateInputs()) return;

      if (!this.provider.id) {
        this.createContract();
      } else {
        this.updateContract();
      }
    },
    createContract() {
      createContractService(this.form.inputs)
        .then(() => {
          const message = this.$t(
            'modalProviderAdd.texts.contractCreatedSuccessfull'
          );
          return this.successModal(message)
            .then(() => this.resetModal())
            .then(() => this.$emit('success'))
            .then(() => this.$refs.modalProviderAdd.hide());
        })
        .catch(err => {
          this.$log.logError(err);
          this.showFailModal(err.message);
        });
    },
    updateContract() {
      updateContractService(this.form.inputs)
        .then(() => {
          const message = this.$t(
            'modalProviderAdd.texts.contractUpdatedSuccessfull'
          );

          return this.successModal(message)
            .then(() => this.resetModal())
            .then(() => this.$emit('success'))
            .then(() => this.$refs.modalProviderAdd.hide());
        })
        .catch(err => {
          this.$log.logError(err);
          this.showFailModal(err.message);
        });
    },
    successModal(title) {
      const configSwal = {
        type: 'success',
        title,
        showConfirmButton: true,
        confirmButtonText: this.$t('modalProviderAdd.words.close'),
      };

      return this.$swal(configSwal);
    },
    showFailModal() {
      const configSwal = {
        type: 'error',
        title: this.$t('modalProviderAdd.texts.thereWasAnError'),
        showConfirmButton: true,
        confirmButtonText: this.$t('modalProviderAdd.words.close'),
      };

      return this.$swal(configSwal);
    },
  },
};
</script>
