var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "access-keep error" }, [
    _c("h1", [_vm._v(_vm._s(_vm.$t("shared.access.errorTitle")))]),
    _c("p", [_vm._v(_vm._s(_vm.$t("shared.access.errorMessage")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }