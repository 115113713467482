import apollo from '@utils/graphql';
import createMutation from '@graphql/contract/mutations/create.gql';
import updateMutation from '@graphql/contract/mutations/update-at.gql';

export const create = (variables, graphqlQuery = createMutation) => apollo.mutate({
  mutation: graphqlQuery,
  variables,
});

export const update = (variables, graphqlQuery = updateMutation) => apollo.mutate({
  mutation: graphqlQuery,
  variables,
});
