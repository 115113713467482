var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "access-guard" },
    [
      _vm.haveAccess
        ? _vm._t("default")
        : _c(
            "div",
            [
              _c(
                "access-error",
                _vm._b({}, "access-error", _vm.componentProps, false)
              ),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }