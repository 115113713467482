var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modalUserAdd",
      attrs: {
        id: "modalUserAdd",
        lazy: "",
        "no-fade": "",
        size: "md",
        title: _vm.modalTitle,
        "cancel-title": _vm.$t("modalUserAdd.words.cancel"),
        "ok-title": _vm.okTitle,
      },
      on: {
        show: _vm.handleOpen,
        cancel: _vm.handleCancel,
        hidden: _vm.handleCancel,
        ok: _vm.createOrUpdateContract,
      },
    },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "data-test": "email",
                    label: _vm.$t("modalUserAdd.texts.userEmail"),
                    "label-for": "input-user-email",
                    state: _vm.form.state.email,
                    "invalid-feedback": "Email inválido",
                  },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "input-user-email",
                      type: "email",
                      trim: "",
                      state: _vm.form.state.email,
                    },
                    model: {
                      value: _vm.form.inputs.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.inputs, "email", $$v)
                      },
                      expression: "form.inputs.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "data-test": "contractId",
                    label: _vm.$t("modalUserAdd.texts.providerName"),
                    "label-for": "input-provider-name",
                    state: _vm.form.state.contractId,
                    "invalid-feedback": "Selecione um fornecedor",
                  },
                  model: {
                    value: _vm.form.inputs.contractId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.inputs, "contractId", $$v)
                    },
                    expression: "form.inputs.contractId",
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      state: _vm.form.state.contractId,
                      options: _vm.form_provider_options,
                      trim: "",
                    },
                    model: {
                      value: _vm.form.inputs.contractId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.inputs, "contractId", $$v)
                      },
                      expression: "form.inputs.contractId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }