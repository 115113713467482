<template>
  <div class="access-guard">
    <slot v-if="haveAccess" />
    <div v-else>
      <access-error
        v-bind="componentProps"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccessError from './access-error'
import { ACL_TYPE } from '@enums/access'

export default {
  name: 'AccessKeeper',
  components: {
    AccessError,
  },
  props: {
    entity: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    ...mapGetters('user', {
      userGroups: 'groups' ,
    }),
    haveAccess() {
      if (!this.userGroups) return false;
      const entityACL = ACL_TYPE[this.entity] || '';
      return this.userGroups.includes(entityACL) || this.userGroups.includes('ACL:GOD');
    },
    componentProps() {
      return {
        userGroups: this.userGroups,
      }
    }
  },
}
</script>


<style lang="scss">
.access-keep {
  font-family: sans-serif;
  font-size: 24px;
  font-weight: normal;
  color: #838383;
  text-align: center;
  padding: 290px 0 60px 0;
  margin-top: 100px;
  background: transparent center 0 no-repeat;
  background-size: auto 45%;

  h1 {
    margin: 2rem auto;
    color: #000;
  }

  span {
    color: #000;
  }

  button {
    margin-top: 1.5rem;
  }
}
</style>
