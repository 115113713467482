<template>
  <div class="access-keep error">
    <h1>{{ $t('shared.access.errorTitle') }}</h1>

    <p>{{ $t('shared.access.errorMessage') }}</p>
  </div>
</template>

<script>
export default {
  name: 'RegionError',
}
</script>

<style lang="scss">
.access-keep.error {
  background-image: url(/img/error.svg);
}
</style>
