import apollo from '@utils/graphql';
import createMutation from '@graphql/supply/mutations/create.gql';
import deleteMutation from '@graphql/supply/mutations/delete.gql';

export const create = (variables, graphqlQuery = createMutation) => apollo.mutate({
  mutation: graphqlQuery,
  variables,
});

export const remove = (variables, graphqlQuery = deleteMutation) => apollo.mutate({
  mutation: graphqlQuery,
  variables,
});
