class KoviForm {
  constructor(data = {}) {
    this.inputs = data;
    this.rules = {};
    this.state = {};
    this.ignoredKeys = [];

    this.init();
  }

  init() {
    this.loadState();
  }

  loadState() {
    Object.keys(this.inputs).forEach(key => {
      this.state[key] = null;
    });
  }

  addKeysToIgnore(...keys) {
    this.ignoredKeys = [
      ...this.ignoredKeys,
      ...keys,
    ];
  }

  resetInputsValidation(formDataDefault = {}) {
    Object.keys(this.inputs).forEach(key => {
      this.state[key] = null;
      this.inputs[key] = formDataDefault[key] || '';
    });
  }

  addRules(rules = {}) {
    this.rules = rules;
  }

  validateInputs() {
    const booleanList = Object.keys(this.inputs)
      .map(key => this._handleValidation(key));

    return !booleanList.some(item => !item);
  }

  _handleValidation(key) {
    if (this.ignoredKeys.includes(key)) {
      return true;
    }

    let validation;
    let hasCustomValidate;
    const value = this.inputs[key];
    const customValidation = this.rules[key];

    if (customValidation) {
      validation = customValidation(value);
      hasCustomValidate = true;
    }

    if (!hasCustomValidate && !this.inputs[key]) {
      return (this.state[key] = false);
    } else if (hasCustomValidate) {
      return (this.state[key] = validation);
    } else {
      return (this.state[key] = true);
    }
  }
}

export default data => new KoviForm(data);
