<template>
  <b-modal
    id="modalUserAdd"
    ref="modalUserAdd"
    lazy
    no-fade
    size="md"
    :title="modalTitle"
    :cancel-title="$t('modalUserAdd.words.cancel')"
    :ok-title="okTitle"
    @show="handleOpen"
    @cancel="handleCancel"
    @hidden="handleCancel"
    @ok="createOrUpdateContract"
  >
    <b-row>
      <b-col sm="12" md="12">
        <b-form-group
          data-test="email"
          :label="$t('modalUserAdd.texts.userEmail')"
          label-for="input-user-email"
          :state="form.state.email"
          invalid-feedback="Email inválido"
        >
          <b-form-input
            id="input-user-email"
            v-model="form.inputs.email"
            type="email"
            trim
            :state="form.state.email"
          />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="12">
        <b-form-group
          v-model="form.inputs.contractId"
          data-test="contractId"
          :label="$t('modalUserAdd.texts.providerName')"
          label-for="input-provider-name"
          :state="form.state.contractId"
          invalid-feedback="Selecione um fornecedor"
        >
          <b-form-select
            v-model="form.inputs.contractId"
            :state="form.state.contractId"
            :options="form_provider_options"
            trim
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import GET_CONTRACT from '@graphql/contract/queries/get-contract.gql';
import { STATUS } from '@enums/provider';
import {
  create as createSupplyUserService,
} from '@graphql/supply/services';
import KoviForm from '@utils/helper-form';

export default {
  name: 'ModalUserAdd',
  props: {
    provider: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    state: {
      addressForm: null,
    },
    form_provider_options: [],
    form: KoviForm({
      contractId: '',
      email: '',
    }),
  }),
  apollo: {
    contracts: {
      query: GET_CONTRACT,
      variables() {
        return {
          page: 0,
          limit: 50,
        }
      },
      result ({ data }) {
        this.form_provider_options = data?.contracts?.items.map((contract) => ({
          text: contract.company_name,
          value: contract.id,
        }));
      }
    },
  },
  computed: {
    okTitle() {
      return this.$t('modalUserAdd.texts.createNewUser');
    },
    modalTitle() {
      return this.$t('modalUserAdd.texts.addNewUser');
    },
  },
  watch: {
    provider(newValue) {
      const hasNewProviderData = Object.keys(newValue).length;

      if (hasNewProviderData) {
        this.form.inputs = {
          ...this.form.inputs,
          contractId: this.provider.id,
        };
      }
    },
  },
  mounted() {
    this.form.addRules({
      email: value => this.validateUser(value)
    });
  },
  methods: {
    validateUser (email) {
      // eslint-disable-next-line no-useless-escape
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    },
    resetModal() {
      this.state.addressForm = false;
      this.form.resetInputsValidation({
        status: STATUS.ACTIVE,
      });
    },
    handleOpen() {
      this.resetModal();
    },
    handleCancel() {
      this.resetModal();
      this.$emit('close');
    },
    handleAddressUpdated(target, event) {
      this.form.inputs[`address_${target}`] = event;
    },
    createOrUpdateContract(eventModal) {
      eventModal.preventDefault();

      if (!this.form.validateInputs()) return;

      this.createContract();
    },
    createContract() {
      createSupplyUserService(this.form.inputs)
        .then(() => {
          const message = this.$t(
            'modalUserAdd.texts.userCreatedSuccessfull'
          );
          return this.successModal(message)
            .then(() => this.resetModal())
            .then(() => this.$emit('success'))
            .then(() => this.$refs.modalUserAdd.hide());
        })
        .catch(err => {
          this.$log.logError(err);
          this.showFailModal(err.message);
        });
    },
    successModal(title) {
      const configSwal = {
        type: 'success',
        title,
        showConfirmButton: true,
        confirmButtonText: this.$t('modalUserAdd.words.close'),
      };

      return this.$swal(configSwal);
    },
    showFailModal() {
      const configSwal = {
        type: 'error',
        title: this.$t('modalUserAdd.texts.thereWasAnError'),
        showConfirmButton: true,
        confirmButtonText: this.$t('modalUserAdd.words.close'),
      };

      return this.$swal(configSwal);
    },
  },
};
</script>
